<template>
  <div class="form-group form-group-checkbox">
    <!--    :class="{ 'invalid-control': invalid }"-->
    <label class="form-group__container">
      <input
        type="checkbox"
        class="form-group__input"
        :checked="value"
        @click="Checkbox__changeChecked($event)"
        :disabled="disabled"
        :keyAdd="keyAdd"
      />
      <span
        class="form-group__checkbox_custom"
        :style="[color && value ? { backgroundColor: color, borderColor: color } : '']"
      >
        <i class="icon-check"></i>
      </span>
      <span class="form-group__label" v-if="label">{{ label }}</span>
      <!--      <small class="invalid-feedback" v-if="invalid">{{ userText }}</small>-->
    </label>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String
    },
    label: {
      type: String
    },
    unique: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    keyAdd: {
      type: [String, Number]
    },
    defaultValue: {
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    editValue: {
      type: [Boolean, String, Number]
    }
  },
  name: "Checkbox",
  model: {
    prop: "checked",
    event: "change"
  },
  data() {
    return {
      value: false
    };
  },
  beforeDestroy() {
    this.$root.$off(`setCheckboxValue_${this.unique}`);
    this.$root.$off(`resetCheckboxValue`);
  },
  mounted() {
    if (this.editValue) {
      this.value = this.editValue;
    }
    this.$root.$on(`setCheckboxValue_${this.unique}`, (value) => {
      this.value = value;
    });
    this.$root.$on(`resetCheckboxValue`, () => {
      this.value = false;
    });
  },
  mixins: [],
  methods: {
    Checkbox__changeChecked(event) {
      this.value = event.target.checked;
      let data = {
        eventName: "Checkbox__changeChecked",
        data: {
          event: event,
          value: this.value,
          label: this.label,
          readonly: this.disabled,
          tooltip: this.tooltip,
          keyAdd: this.keyAdd,
          defaultValue: this.defaultValue,
          required: this.required
        }
      };
      this.$root.$emit(`checkbox:change_${this.unique}`, this.value);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.form-group {
  margin-bottom: 0;
  width: 100%;
  &__container {
    width: 100%;

    margin-bottom: 0;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    display: none;
    width: 0;
    height: 0;
    font-size: 0;

    &:checked + .form-group__checkbox_custom {
      background-color: $action-primary;
      border: 2px solid $action-primary;
    }
  }

  &__checkbox_custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 2px solid #d3d2e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;
    flex-shrink: 0;
    .icon-check {
      color: #ffffff;
    }
  }

  &__label {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
  }
}
</style>
